

























































.mainNoFound{
    min-height:465px
}
.noFound{
    display:block;
    width:200px;
    margin:70px auto 0;
}
.backIndex{
    display:block;
    width:155px;
    height:40px;
    font-size:16px;
    color:#fff;
    text-align: center;
    line-height:40px;
    background:#00AFE8;
    border-radius: 4px;
    margin:20px auto 0;
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";