.mainNoFound {
  min-height: 465px;
}
.noFound {
  display: block;
  width: 200px;
  margin: 70px auto 0;
}
.backIndex {
  display: block;
  width: 155px;
  height: 40px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  background: #00AFE8;
  border-radius: 4px;
  margin: 20px auto 0;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
