.mainNoFound[data-v-45724cdc] {
  min-height: 12.4rem;
}
.noFound[data-v-45724cdc] {
  display: block;
  width: 5.33333rem;
  margin: 1.86667rem auto 0;
}
.backIndex[data-v-45724cdc] {
  display: block;
  width: 4.13333rem;
  height: 1.06667rem;
  font-size: 0.42667rem;
  color: #fff;
  text-align: center;
  line-height: 1.06667rem;
  background: #00AFE8;
  border-radius: 0.10667rem;
  margin: 0.53333rem auto 0;
}
.ellipsis[data-v-45724cdc] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-45724cdc] {
  *zoom: 1;
}
.clearfix[data-v-45724cdc]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-45724cdc] {
  float: left;
}
.fr[data-v-45724cdc] {
  float: right;
}


/*# sourceMappingURL=chunk-36e2a6d3.36f76f3a.css.map*/